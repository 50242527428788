var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar"},[_c('lot-search',{staticClass:"hidden-below-md",attrs:{"size":"small","isReduced":true}}),_c('div',{attrs:{"id":"auex-live-ticker-wrapper"}},_vm._l((_vm.auexLiveTickerAuctions),function(liveBiddingTicker){return _c('sidebar-box-auex-ticker',{key:liveBiddingTicker.auctionId,attrs:{"baseUrl":'https://' + liveBiddingTicker.hostname,"auctionId":liveBiddingTicker.auctionId}})}),1),_c('div',{attrs:{"id":"bidinside-live-ticket-wrapper"}},_vm._l((_vm.bidInsideLiveTickerAuctions),function(liveBiddingTicker){return _c('sidebar-box-bid-inside-ticker',{key:liveBiddingTicker.auctionid,attrs:{"tickerUrl":liveBiddingTicker.ticker_url,"auctionId":liveBiddingTicker.auctionid}})}),1),(
            _vm.flexibleTeaserBoxes['1-flexible-box'] &&
            _vm.flexibleTeaserBoxes['1-flexible-box'].boxVisible == 1
        )?_c('sidebar-box',{attrs:{"headline":_vm.flexibleTeaserBoxes['1-flexible-box'].headline}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.flexibleTeaserBoxes['1-flexible-box'].content)}})]):_vm._e(),(
            _vm.flexibleTeaserBoxes['2-flexible-box'] &&
            _vm.flexibleTeaserBoxes['2-flexible-box'].boxVisible == 1
        )?_c('sidebar-box',{attrs:{"headline":_vm.flexibleTeaserBoxes['2-flexible-box'].headline}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.flexibleTeaserBoxes['2-flexible-box'].content)}})]):_vm._e(),_c('sidebar-box-highlight-special'),_c('sidebar-box-highlight'),(
            _vm.flexibleTeaserBoxes['3-flexible-box'] &&
            _vm.flexibleTeaserBoxes['3-flexible-box'].boxVisible == 1
        )?_c('sidebar-box',{attrs:{"headline":_vm.flexibleTeaserBoxes['3-flexible-box'].headline}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.flexibleTeaserBoxes['3-flexible-box'].content)}})]):_vm._e(),(
            _vm.flexibleTeaserBoxes['4-flexible-box'] &&
            _vm.flexibleTeaserBoxes['4-flexible-box'].boxVisible == 1
        )?_c('sidebar-box',{attrs:{"headline":_vm.flexibleTeaserBoxes['4-flexible-box'].headline}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.flexibleTeaserBoxes['4-flexible-box'].content)}})]):_vm._e(),_c('coin-collector'),_c('sidebar-box-best-coins'),(_vm.getAds['Sidebar-3'] || _vm.getAds['Sidebar-4'])?_c('div',{staticClass:"sidebar-box-group"},[_c('div',{staticClass:"sidebar-box-slots"},[(_vm.getAds['Sidebar-3'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['Sidebar-3']}}):_vm._e()],1),_c('div',{staticClass:"sidebar-box-slots"},[(_vm.getAds['Sidebar-4'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['Sidebar-4']}}):_vm._e()],1)]):_vm._e(),(_vm.getAds['Sidebar-1'] || _vm.getAds['Sidebar-2'])?_c('div',{staticClass:"sidebar-box-group"},[_c('div',{staticClass:"sidebar-box-slots"},[(_vm.getAds['Sidebar-1'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['Sidebar-1']}}):_vm._e()],1),_c('div',{staticClass:"sidebar-box-slots"},[(_vm.getAds['Sidebar-2'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['Sidebar-2']}}):_vm._e()],1)]):_vm._e(),_c('sidebar-box',{attrs:{"headline":_vm.$t('news_numismatic')}},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('news_numismatic_world'))+"\n        ")]),_c('p',{staticClass:"powered-by-coinsweekly",domProps:{"innerHTML":_vm._s(_vm.$t('powered_by_coinsweekly'))}}),_c('news-list')],1),_c('sidebar-box',{attrs:{"headline":_vm.$t('history_week')}},[_c('twih-list')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }