<template>
    <div class="account-page" id="account-watchlist-page">
        <div class="row">
            <account-navigation />
            <div class="col col-md-12 col-lg-12">
                <div class="col-wrapper">
                    <datashare-revoke></datashare-revoke>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountNavigation from '../components/AccountNavigation';
import DatashareRevoke from '../components/DatashareRevoke';

export default {
    name: 'Account_Profile',
    components: {
        AccountNavigation,
        DatashareRevoke,
    },
    data: function () {
        return {
        };
    },
    methods: {
    },
    mounted() {
        this.$root.$on('update:language', () => {
            this.$forceUpdate();
        });
    },
};
</script>

<style scoped lang="scss">
</style>
