<template>
    <ul class="auction-tabs tabs">
        <li>
            <router-link
                class="button button-grey icon-lots"
                :class="{ active: $route.name == 'page.auctionLotList' }"
                :to="{
                    name: 'page.auctionLotList',
                    params: { page: currentPage, perPage: itemsPerPage },
                    query: this.$route.query,
                }"
            >
                <span>{{ $t('lots') }}</span>
            </router-link>
        </li>
        <li>
            <router-link
                class="button button-grey icon-info"
                :class="{ active: $route.name == 'page.auctionInformation' }"
                :to="{
                    name: 'page.auctionInformation',
                    query: this.$route.query,
                }"
            >
                <span>{{ $t('auction_information') }}</span>
            </router-link>
        </li>
        <li>
            <router-link
                class="button button-grey icon-conditions"
                :class="{ active: $route.name == 'page.auctionConditions' }"
                :to="{
                    name: 'page.auctionConditions',
                    query: this.$route.query,
                }"
            >
                <span>{{ $t('auction_conditions') }}</span>
            </router-link>
        </li>
        <li>
            <router-link
                class="button button-grey icon-more"
                :class="{ active: $route.name == 'page.auctionMore' }"
                :to="{ name: 'page.auctionMore', query: this.$route.query }"
            >
                <span>{{ $t('company_auctions_more') }}</span>
            </router-link>
        </li>
        <li v-if="bidSheetLots">
            <router-link
                class="button button-grey icon-sheet"
                :class="{ active: $route.name == 'page.bidSheet' }"
                :to="{ name: 'page.bidSheet', query: this.$route.query }"
            >
                <span>{{ $t('bid_sheet') }}</span>
                <span>&nbsp;(</span>
                <span class="bidSheetLots">{{ bidSheetLots }}</span>
                <span>)</span>
            </router-link>
        </li>
        <li
            v-if="auction.auctionPrLink || auction.auctionPrAvailable"
            :class="liveBiddingActive ? 'hidden-below-lg' : ''"
        >
            <a
                class="button button-grey icon-results"
                :class="{ active: $route.name == 'page.auctionResults' }"
                :href="auction.auctionPrLink"
                v-if="
                    auction.auctionPrLink != '' && auction.auctionPrLink != null
                "
                target="_blank"
            >
                <span>{{ $t('results') }}</span>
            </a>
            <router-link
                class="button button-grey icon-results"
                :class="{ active: $route.name == 'page.auctionResults' }"
                v-if="auction.auctionPrAvailable == 1"
                :to="{
                    name: 'page.auctionResults',
                    params: {
                        companySlug: auction.companySlug,
                        auctionId: auctionId,
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                    query: this.$route.query,
                }"
            >
                <span>{{ $t('results') }}</span>
            </router-link>
        </li>
        <li v-if="liveBiddingActive">
            <router-link
                class="button button-blue icon-hammer"
                :class="{ active: $route.name == 'page.auctionLivebidding' }"
                v-if="true || todo"
                :to="{
                    name: 'page.auctionLivebidding',
                    params: {
                        companySlug: auction.companySlug,
                        auctionId: auctionId,
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                    query: this.$route.query,
                }"
            >
                <span>{{ $t('livebidding') }}</span>
            </router-link>
        </li>

        <li v-if="showSorting" class="sorting" @click="$emit('sorting')">
            <span class="link no-underline icon-search">
                <i class="fas fa-search"></i>
            </span>
        </li>
    </ul>
</template>

<script>
import { PAGINATION_DEFAULTS } from '../config.js';
import { COIN_COLLECTOR } from '../config';
import { getAuctionLiveBiddingStatusCode } from '../lib/apis';

export default {
    props: {
        auctionId: {
            type: [String, Number],
            required: true,
        },
        auction: {
            type: Object,
            required: true,
        },
        auctionStatus: {
            type: Object,
            required: false,
        },
        showSorting: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            target: COIN_COLLECTOR.TARGET,
            cctaburl: COIN_COLLECTOR.TABURL,
            auctionStatusLocal: {
                found: false,
                status: 0,
            },
        };
    },
    methods: {
        getLivebiddingStatus: function () {
            if (this.auction.liveBiddingUrls && this.auction.auctionId) {
                const livebiddingProviderUrl = Object.values(
                    this.auction.liveBiddingUrls
                )[0];
                if (livebiddingProviderUrl) {
                    getAuctionLiveBiddingStatusCode(
                        livebiddingProviderUrl,
                        this.auction.auctionId
                    ).then((result) => {
                        this.auctionStatusLocal = result.data;
                    });
                }
            }
        },
    },
    mounted: function () {
        // if prop auctionStatus is not provided catch data here
        if (typeof this.auctionStatus == 'undefined') {
            // setup periodic polling of livebidding status
            setInterval(
                function () {
                    this.getLivebiddingStatus();
                }.bind(this),
                30000
            );

            // request once up-front so we don't need to wait
            this.getLivebiddingStatus();
        }
    },
    computed: {
        bidSheetLots() {
            const auctionId = Number(this.auctionId);

            if (
                this.bidSheets &&
                this.bidSheets[auctionId] &&
                Object.keys(this.bidSheets).length > 0
            ) {
                return this.bidSheets[auctionId].length;
            }
            return false;
        },
        currentPage() {
            if (this.$route.query.page) {
                return this.$route.query.page;
            }
            return PAGINATION_DEFAULTS.START_PAGE;
        },
        itemsPerPage() {
            if (this.$route.query.limit) {
                return this.$route.query.limit;
            }
            return PAGINATION_DEFAULTS.PER_PAGE;
        },
        liveBiddingActive() {
            if (typeof this.auctionStatus == 'undefined') {
                return (
                    this.auctionStatusLocal.found &&
                    this.auctionStatusLocal.status > 0 &&
                    this.auctionStatusLocal.status < 4
                );
            } else {
                return this.auctionStatus.found;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.active {
    background: $dark-bg;
    color: white;
}

.auction-tabs button {
    text-transform: uppercase;
}

@media (max-width: $md-size-max) {
    li {
        margin: $mar-sm;
        padding: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    a[class*='icon'] {
        position: relative;
        width: 45px;
        height: 45px;
        padding: 0;

        &:before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 20px;
            padding: 5px;
            margin: 0 auto;
        }

        span {
            display: none;
        }
    }

    .icon-lots {
        &:before {
            content: '\f009';
        }
    }

    .icon-info {
        &:before {
            content: '\f05a';
        }
    }

    .icon-conditions {
        &:before {
            content: '\f15c';
        }
    }

    .icon-results {
        &:before {
            content: '\f0cb';
        }
    }

    .icon-hammer {
        &:before {
            content: '\f0e3';
        }
    }

    .icon-sheet {
        &:before {
            content: '\f328';
        }

        .bidSheetLots {
            display: block;
            position: absolute;
            top: -15px;
            right: -15px;
            height: 30px;
            width: 30px;
            line-height: 30px;
            text-align: center;
            font-weight: bold;
            font-size: 10px;

            color: white;
            background: $main-color;
            border-radius: 50px;
        }
    }

    .icon-more {
        &:before {
            content: '\f0e8';
        }
    }

    .sorting {
        margin-left: auto;
        width: 45px;

        span {
            display: inline-block;
            margin: 0 auto;
        }
    }

    .icon-search {
        font-size: 1.5em;
    }
}
</style>
