var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"auction-tabs tabs"},[_c('li',[_c('router-link',{staticClass:"button button-grey icon-lots",class:{ active: _vm.$route.name == 'page.auctionLotList' },attrs:{"to":{
                name: 'page.auctionLotList',
                params: { page: _vm.currentPage, perPage: _vm.itemsPerPage },
                query: this.$route.query,
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('lots')))])])],1),_c('li',[_c('router-link',{staticClass:"button button-grey icon-info",class:{ active: _vm.$route.name == 'page.auctionInformation' },attrs:{"to":{
                name: 'page.auctionInformation',
                query: this.$route.query,
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('auction_information')))])])],1),_c('li',[_c('router-link',{staticClass:"button button-grey icon-conditions",class:{ active: _vm.$route.name == 'page.auctionConditions' },attrs:{"to":{
                name: 'page.auctionConditions',
                query: this.$route.query,
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('auction_conditions')))])])],1),_c('li',[_c('router-link',{staticClass:"button button-grey icon-more",class:{ active: _vm.$route.name == 'page.auctionMore' },attrs:{"to":{ name: 'page.auctionMore', query: this.$route.query }}},[_c('span',[_vm._v(_vm._s(_vm.$t('company_auctions_more')))])])],1),(_vm.bidSheetLots)?_c('li',[_c('router-link',{staticClass:"button button-grey icon-sheet",class:{ active: _vm.$route.name == 'page.bidSheet' },attrs:{"to":{ name: 'page.bidSheet', query: this.$route.query }}},[_c('span',[_vm._v(_vm._s(_vm.$t('bid_sheet')))]),_c('span',[_vm._v(" (")]),_c('span',{staticClass:"bidSheetLots"},[_vm._v(_vm._s(_vm.bidSheetLots))]),_c('span',[_vm._v(")")])])],1):_vm._e(),(_vm.auction.auctionPrLink || _vm.auction.auctionPrAvailable)?_c('li',{class:_vm.liveBiddingActive ? 'hidden-below-lg' : ''},[(
                _vm.auction.auctionPrLink != '' && _vm.auction.auctionPrLink != null
            )?_c('a',{staticClass:"button button-grey icon-results",class:{ active: _vm.$route.name == 'page.auctionResults' },attrs:{"href":_vm.auction.auctionPrLink,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('results')))])]):_vm._e(),(_vm.auction.auctionPrAvailable == 1)?_c('router-link',{staticClass:"button button-grey icon-results",class:{ active: _vm.$route.name == 'page.auctionResults' },attrs:{"to":{
                name: 'page.auctionResults',
                params: {
                    companySlug: _vm.auction.companySlug,
                    auctionId: _vm.auctionId,
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
                query: this.$route.query,
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('results')))])]):_vm._e()],1):_vm._e(),(_vm.liveBiddingActive)?_c('li',[(true || _vm.todo)?_c('router-link',{staticClass:"button button-blue icon-hammer",class:{ active: _vm.$route.name == 'page.auctionLivebidding' },attrs:{"to":{
                name: 'page.auctionLivebidding',
                params: {
                    companySlug: _vm.auction.companySlug,
                    auctionId: _vm.auctionId,
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
                query: this.$route.query,
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('livebidding')))])]):_vm._e()],1):_vm._e(),(_vm.showSorting)?_c('li',{staticClass:"sorting",on:{"click":function($event){return _vm.$emit('sorting')}}},[_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"link no-underline icon-search"},[_c('i',{staticClass:"fas fa-search"})])}]

export { render, staticRenderFns }