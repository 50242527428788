<template>
    <sidebar-box
        v-bind:style="{ order: auction.companyId }"
        v-if="tickerItem.status && tickerItem.status > 0 && tickerItem.auctionId === auctionId"
        headline="Sixbid Live Bidding"
        theme="black"
        :bidinside="true"
    >
        <div class="live-bidding-box">
            <div class="bidinside-live-bidding-img-wrapper">
                <img
                    :alt="$t('lot')"
                    loading="lazy"
                    v-if="tickerItem.imgUrl1"
                    :src="tickerItem.imgUrl1"
                />
                <img
                    :alt="$t('lot')"
                    loading="lazy"
                    v-if="tickerItem.imgUrl2"
                    :src="tickerItem.imgUrl2"
                />
            </div>
            <p>
                <span class="bidinside-live-bidding-company">{{
                    auction.companyName
                }}</span
                ><br />
                <span class="bidinside-live-bidding-auction"
                    >{{ auction.auctionName }}, {{ $t('lot') }}
                    {{ tickerItem.losnummer }}</span
                ><br />
                <span class="bidinside-live-bidding-price"></span
                ><span v-if="tickerItem.status == 5">{{
                    $t('bid_hammer')
                }}</span
                ><span v-else-if="tickerItem.status == 7">{{
                    $t('bid_unsold')
                }}</span
                ><span v-else-if="tickerItem.status == 8">{{
                    $t('bid_startingprice')
                }}</span
                ><span v-else>{{ $t('bid_currentbid') }}</span
                ><span v-if="tickerItem.status != 7"
                    >:
                    {{
                        tickerItem.gebot ? tickerItem.gebot : tickerItem.ausruf
                    }}
                    {{ tickerItem.waehrung }}</span
                >
                &nbsp;<i
                    v-if="tickerItem.status == 5"
                    class="fa fa-gavel icon-hammer"
                ></i>
            </p>
            <router-link
                class="button button-blue live-bidding-cta-button"
                :to="{
                    name: 'page.auctionLivebidding',
                    params: {
                        companySlug: auction.companySlug,
                        auctionId: auctionId,
                    },
                }"
                >{{ $t('bid_livenow') }}</router-link
            >
        </div>
    </sidebar-box>
</template>

<script>
import SidebarBox from '../components/SidebarBox';
import { getAuctionDetails } from '../lib/apis';

const RECONNECT_INTERVAL = 1000;
const RECONNECT_INTERVAL_ON_ERROR = 100000;

export default {
    name: 'SidebarBoxBidInsideTicker',
    components: {
        SidebarBox,
    },
    data: function () {
        return {
            tickerItem: {},
            auction: {},
            connection: null,
            connectionEstablished: false,
            connectionShouldReconnect: true,
        };
    },
    props: {
        tickerUrl: {
            type: String,
            required: true,
        },
        auctionId: {
            type: String,
            required: true,
        },
    },
    mounted: function () {
        // attach to bidinside websocket
        this.connection = new WebSocket(this.tickerUrl);

        this.connection.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.tickerItem = { ...data, status: Number(data.status) };
        };

        this.connection.onclose = () => {
            if (this.connectionShouldReconnect) {
                setTimeout(
                    () => {
                        this.connection = new WebSocket(this.tickerUrl);
                    },
                    this.connectionEstablished
                        ? RECONNECT_INTERVAL
                        : RECONNECT_INTERVAL_ON_ERROR
                );
            }
        };

        getAuctionDetails(null, '_', this.auctionId, this.$route.params.lang)
            .then((response) => {
                this.auction = response.data;

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );
                });
            })
            .catch((e) => {
                this.genericApiErrorHandling(e);
            });
    },
    beforeDestroy: function () {
        // detach from bidinside websocket
        this.connectionShouldReconnect = false;
        this.connection.close();
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-hammer:before {
    font-size: 0.8;
}
.live-bidding-box {
    background-color: white;
    margin: 15px 0 15px 0;
    padding: 15px;
    color: black;
    text-align: center;
}
.bidinside-live-bidding-img-wrapper {
    width: 100%;
    max-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    max-width: 100%;
    overflow: hidden;
}

.bidinside-live-bidding-img-wrapper img {
    max-width: 50%;
    height: auto;
    flex: 1;
}

.bidinside-live-bidding-company {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 0.5em;
    display: inline-block;
}
.bidinside-live-bidding-auction {
    margin-bottom: 0.5em;
    display: inline-block;
}
.live-bidding-cta-button {
    width: 100%;
}
</style>
