var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction-lot-list-page"},[_c('auction-banner',{attrs:{"companyId":_vm.company.companyId,"auctionId":_vm.auction.auctionId}}),_c('breadcrumb',[_vm._v("\n        "+_vm._s(_vm.auction.auctionName)+":\n        "),_c('span',[_vm._v(_vm._s(_vm._f("striphtml")(_vm._f("removeBR")(_vm.auction.auctionDescription))))])]),(_vm.auction.auctionId)?_c('auction-tabs',{attrs:{"auctionId":_vm.auction.auctionId,"auction":_vm.auction,"showSorting":_vm.$global.isMobile},on:{"sorting":function($event){_vm.viewShown = !_vm.viewShown}}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-4 col-lg-3 lot-list-sidebar-container"},[_c('div',{staticClass:"lot-list-sidebar"},[_c('sidebar-box',{staticClass:"sidebarsection sidebarsection-first auction-info"},[(_vm.auction.auctionHasNGC)?_c('a',{staticClass:"ngc",on:{"click":function($event){_vm.overlayShown = true}}},[_vm._v(_vm._s(_vm.$t('grading_ngc')))]):_vm._e(),(_vm.auction.auctionHasNGC)?_c('div',{staticClass:"clearfix ngcClearfix"}):_vm._e(),_c('h3',{staticClass:"sidebar-box-headline auction-headline"},[_vm._v("\n                        "+_vm._s(_vm.auction.auctionName)+"\n                    ")]),(
                            _vm.auction.auctionLocationName ||
                            _vm.auction.auctionLocationStreet ||
                            _vm.auction.auctionLocationZip ||
                            _vm.auction.auctionLocationCity ||
                            _vm.auction.auctionLocationCountry
                        )?_c('div',[_c('p',[_vm._v("\n                            "+_vm._s(_vm.auction.auctionLocationCity)),(
                                    _vm.auction.auctionLocationCity &&
                                    _vm.auction.auctionLocationCountry
                                )?_c('span',[_vm._v(",")]):_vm._e(),_vm._v("\n                            "+_vm._s(_vm.auction.auctionLocationCountry)+"\n                        ")]),(_vm.auction.auctionLocationAdditionalText)?_c('p',[_vm._v("\n                            "+_vm._s(_vm.auction.auctionLocationAdditionalText)+"\n                        ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"auctionDate"},[_vm._v("\n                        "+_vm._s(_vm.date)+"\n                    ")]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"auctionLivebiddingButtonWrapper"},[(
                                _vm.auction.auctionLiveBiddingLink &&
                                !_vm.liveBiddingActive
                            )?_c('a',{staticClass:"button button-dark-grey",attrs:{"href":_vm.auction.auctionLiveBiddingLink,"target":"_blank"}},[_vm._v("\n                            "+_vm._s(_vm.$t('livebidding_register'))+"\n                        ")]):_vm._e(),(_vm.liveBiddingActive)?_c('router-link',{staticClass:"button button-blue color-white",class:{
                                active:
                                    _vm.$route.name ==
                                    'page.auctionLivebidding',
                            },attrs:{"to":{
                                name: 'page.auctionLivebidding',
                                params: {
                                    companySlug: _vm.auction.companySlug,
                                    auctionId: _vm.auction.auctionId,
                                },
                                query: this.$route.query,
                            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('livebidding.sixbid')))])]):_vm._e()],1),(_vm.auction.auctionLiveBiddingLink)?_c('div',{staticClass:"clearfix"}):_vm._e(),_c('div',{staticClass:"auctionOnlineBiddingEndTitle"},[_vm._v("\n                        "+_vm._s(_vm.$t('bid_end'))+":\n                    ")]),_vm._l((_vm.sessions),function(session){return _c('div',{key:session.sessionId},[_c('div',{staticClass:"auctionOnlineBiddingEnd"},[_c('span',{staticClass:"auctionOnlineBiddingEndLabel"},[_vm._v(_vm._s(_vm.$t('part'))+"\n                                "+_vm._s(session.sessionLabel)+"\n                                "),_c('span',{staticClass:"light"},[_vm._v("("+_vm._s(session.minLotId)+" -\n                                    "+_vm._s(session.maxLotId)+"):")])]),_c('span',{staticClass:"auctionOnlineBiddingEndDate"},[(session.sessionEnds)?_c('date-timezone',{attrs:{"date":session.sessionEnds}}):_vm._e()],1)])])}),_c('div',{staticClass:"clearfix"})],2),(_vm.viewShown || !_vm.$global.isMobile)?_c('sidebar-box',{attrs:{"headline":_vm.$t('auction_search')}},[(_vm.$global.isMobile)?_c('span',{staticClass:"closeButton",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.viewShown = false}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitSearch()}}},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lotSearchForm.term),expression:"lotSearchForm.term"}],attrs:{"type":"text","placeholder":_vm.$t('search_term_lot'),"name":"term","id":"term"},domProps:{"value":(_vm.lotSearchForm.term)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.lotSearchForm, "term", $event.target.value)}}})]),_c('div',{staticClass:"group price"},[_c('div',{staticClass:"priceFromBox"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                                            _vm.lotSearchForm.priceFrom
                                        ),expression:"\n                                            lotSearchForm.priceFrom\n                                        ",modifiers:{"number":true}}],attrs:{"type":"text","name":"priceFrom","id":"priceFrom","placeholder":_vm.$t('lot_price_more')},domProps:{"value":(
                                            _vm.lotSearchForm.priceFrom
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.lotSearchForm, "priceFrom", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('strong',{staticClass:"currency-addon"},[_vm._v(_vm._s(_vm.auction.auctionCurrency))])])]),_c('div',{staticClass:"form-submit"},[_c('input',{staticClass:"button button-blue search-form-submit",attrs:{"type":"submit"},domProps:{"value":_vm.$t('search_start')}})])])]):_vm._e(),(_vm.auction.auctionLiveBiddingLink)?_c('a',{staticClass:"button button-dark-grey hidden-above-md",attrs:{"href":_vm.auction.auctionLiveBiddingLink,"target":"_blank"}},[_vm._v("\n                    "+_vm._s(_vm.$t('livebidding_register'))),_c('i',{staticClass:"fas fa-external-link-alt fa-sm"})]):_vm._e(),(_vm.$global.isMobile)?_c('a',{staticClass:"viewOptionsToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showViewOptions = !_vm.showViewOptions;
                        _vm.showCategoryOptions = false;}}},[_vm._v(_vm._s(_vm.$t('view_adjust')))]):_vm._e(),(_vm.$global.isMobile)?_c('a',{staticClass:"categoryOptionsToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleCategories()}}},[_vm._v(_vm._s(_vm.$t('category_choose')))]):_vm._e(),(_vm.$global.isMobile)?_c('div',{staticClass:"clearfix"}):_vm._e(),(
                        !_vm.$global.isMobile ||
                        (_vm.$global.isMobile && _vm.showViewOptions)
                    )?_c('sidebar-box',{staticClass:"viewOptionsBox",attrs:{"headline":_vm.$t('view_adjust')}},[(_vm.$global.isMobile)?_c('span',{staticClass:"closeButton",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showViewOptions = false}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('div',{staticClass:"form"},[_c('div',{staticClass:"group sort"},[_c('span',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('sort_by'))+":")])]),_c('span',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.lotListSortOn),expression:"lotListSortOn"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.lotListSortOn=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeSorting]}},[_c('option',{attrs:{"value":"lot_number"}},[_vm._v("\n                                        "+_vm._s(_vm.$t('lot_nr'))+"\n                                    ")]),_c('option',{attrs:{"value":"starting_price"}},[_vm._v("\n                                        "+_vm._s(_vm.$t('lot_estimate'))+"\n                                    ")])]),_c('a',{staticClass:"icon",class:{
                                        active: _vm.lotListSortOrder === 'asc',
                                    },on:{"click":function($event){_vm.lotListSortOrder = 'asc';
                                        _vm.handleChange(true);
                                        _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-long-arrow-alt-up"})]),_c('a',{staticClass:"icon",class:{
                                        active: _vm.lotListSortOrder === 'desc',
                                    },on:{"click":function($event){_vm.lotListSortOrder = 'desc';
                                        _vm.handleChange(true);
                                        _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-long-arrow-alt-down"})])])]),_c('div',{staticClass:"group results hidden-below-md"},[_c('span',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('lots_per_page'))+":")])]),_c('span',[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.lotListResultsPerPage),expression:"lotListResultsPerPage",modifiers:{"number":true}}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.lotListResultsPerPage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.handleChange(true);
                                        _vm.scrollToTop();}]}},[_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])])]),_c('div',{staticClass:"group view hidden-below-md"},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.$t('view'))+":")])]),_c('span',[_c('a',{staticClass:"icon hidden-below-lg",class:{
                                        active:
                                            _vm.lotListDisplayMode === 'small',
                                    },on:{"click":function($event){_vm.lotListDisplayMode = 'small';
                                        _vm.handleChange();
                                        _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-th"})]),_c('a',{staticClass:"icon",class:{
                                        active:
                                            _vm.lotListDisplayMode === 'large',
                                    },on:{"click":function($event){_vm.lotListDisplayMode = 'large';
                                        _vm.handleChange();
                                        _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('a',{staticClass:"icon",class:{
                                        active:
                                            _vm.lotListDisplayMode === 'detail',
                                    },on:{"click":function($event){_vm.lotListDisplayMode = 'detail';
                                        _vm.handleChange();
                                        _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-th-list"})])])])])]):_vm._e(),_c('div',{staticClass:"sidebar-box-container categoryOptions",class:{
                        stick: _vm.sidebarIsSticky,
                        nonstick: !_vm.sidebarIsSticky,
                    }},[_c('sidebar-box',{directives:[{name:"show",rawName:"v-show",value:(
                            !_vm.$global.isMobile ||
                            (_vm.$global.isMobile && _vm.showCategoryOptions)
                        ),expression:"\n                            !$global.isMobile ||\n                            ($global.isMobile && showCategoryOptions)\n                        "}],ref:"allSessions",staticClass:"categoryOptionsBox"},[_c('div',{staticClass:"stickyToggleContainer"},[(!_vm.sidebarIsSticky)?_c('a',{staticClass:"stickyToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleStickySidebar()}}},[_vm._v(_vm._s(_vm.$t('pin')))]):_c('a',{staticClass:"stickyToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleStickySidebar()}}},[_vm._v(_vm._s(_vm.$t('unpin')))])]),(_vm.$global.isMobile)?_c('span',{staticClass:"closeButton categoryCloseButton",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleCategories()}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('div',{staticClass:"sessionlist-scroll"},[_c('h3',{staticClass:"sidebar-box-headline sidebar-box-headline--link",on:{"click":function($event){$event.preventDefault();_vm.deselectAll();
                                    _vm.handleChange(true);
                                    _vm.scrollToTop();
                                    _vm.showCategoryOptions = false;}}},[_vm._v("\n                                "+_vm._s(_vm.$t('lot_show_all'))+" ("+_vm._s(_vm.totalLots)+")\n                            ")]),_c('div',{staticClass:"session-wrapper"},_vm._l((_vm.sessions),function(session){return _c('div',{key:session.sessionId,staticClass:"sessions form"},[_c('div',{ref:"SessionPart",refInFor:true,staticClass:"session-part"},[(Object.keys(_vm.sessions).length > 1)?_c('p',{staticClass:"session-part-headline",on:{"click":function($event){return _vm.toggleVisibilty(
                                                    session.sessionId
                                                )}}},[_vm._v("\n                                            "+_vm._s(_vm.$t('part'))+"\n                                            "+_vm._s(session.sessionLabel)+"\n                                            "),(session.show)?_c('i',{staticClass:"fas fa-chevron-up"}):_c('i',{staticClass:"fas fa-chevron-down"})]):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(session.show)?_c('div',{staticClass:"session-part-scroll"},_vm._l((session.sessionCategories),function(category){return _c('div',{key:category.categoryId,staticClass:"session-part-filter"},[_c('a',{staticClass:"session-filter",class:{
                                                            'active session-filter--disabled':
                                                                _vm.lotListQuery.auctionSessions.split(
                                                                    '|'
                                                                )[1] ==
                                                                category.categoryId,
                                                        },on:{"click":function($event){$event.preventDefault();_vm.deselectAll();
                                                            _vm.changeSession(
                                                                session.sessionId,
                                                                category.categoryId
                                                            );
                                                            _vm.scrollToElement(
                                                                '.auction-info'
                                                            );
                                                            _vm.showCategoryOptions = false;}}},[_vm._v(_vm._s(category.categoryName)+"\n                                                        ("+_vm._s(category.lotCount)+")")])])}),0):_vm._e()])],1)])}),0)])])],1)],1)]),_c('div',{staticClass:"col col-md-8 col-lg-9"},[_c('div',{staticClass:"lot-list-modes hidden-below-md"},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.$t('view'))+":")])]),_c('span',[_c('a',{staticClass:"icon hidden-below-lg",class:{ active: _vm.lotListDisplayMode === 'small' },on:{"click":function($event){_vm.lotListDisplayMode = 'small';
                            _vm.handleChange();
                            _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-th"})]),_c('a',{staticClass:"icon",class:{ active: _vm.lotListDisplayMode === 'large' },on:{"click":function($event){_vm.lotListDisplayMode = 'large';
                            _vm.handleChange();
                            _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('a',{staticClass:"icon",class:{ active: _vm.lotListDisplayMode === 'detail' },on:{"click":function($event){_vm.lotListDisplayMode = 'detail';
                            _vm.handleChange();
                            _vm.scrollToTop();}}},[_c('i',{staticClass:"fas fa-th-list"})])])]),(_vm.company.companySlug && _vm.auction.auctionId)?_c('lot-list-component',{attrs:{"company":_vm.company,"auctionId":_vm.auction.auctionId,"searchQuery":_vm.lotListQuery,"sortOn":_vm.lotListSortOn,"sortOrder":_vm.lotListSortOrder,"resultsPerPage":_vm.lotListResultsPerPage,"displayMode":_vm.lotListDisplayMode,"showEstimatePrice":false,"showResultPrice":parseInt(_vm.auction.auctionPrAvailable, 10) == 1,"showBidding":parseInt(_vm.auction.auctionPrAvailable, 10) == 0},on:{"pagination-clicked":function($event){return _vm.scrollToTop()}}}):_vm._e()],1)]),_c('content-overlay',{attrs:{"shown":_vm.overlayShown},on:{"closing":function($event){_vm.overlayShown = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.ngcContent)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }