<template>
    <div class="auction-lot-detail">
        <auction-banner
            class="no-print"
            :companyId="auction.companyId"
            :auctionId="auction.auctionId"
        ></auction-banner>

        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
            >
                {{ auction.auctionName }}:
                <span>{{
                    auction.auctionDescription | removeBR | striphtml
                }}</span>
            </router-link>
            / {{ $t('lot') }}: {{ lot.lotNumber }}{{ lot.lotNumberAffix }}
        </breadcrumb>

        <auction-tabs
            class="no-print"
            :companySlug="$route.params.companySlug"
            :auctionId="$route.params.auctionId"
            :auction="auction"
            :auctionStatus="auctionStatusLocal"
            :showSorting="$global.isMobile"
            @sorting="viewShown = !viewShown"
        ></auction-tabs>

        <div class="grid">
            <div class="row">
                <aside class="lot-info col col-md-3" v-if="lot.lotId">
                    <sidebar-box>
                        <div class="datesbox">
                            <a
                                v-if="auction.auctionHasNGC"
                                class="ngc"
                                @click="overlayShown = true"
                                >{{ $t('grading_ngc') }}</a
                            >

                            <h2 class="style-like-h3 auction">
                                {{ auction.auctionName }}
                            </h2>
                            <h1 class="style-like-h3 nr">
                                {{ $t('lot') }} {{ lot.lotNumber
                                }}{{ lot.lotNumberAffix }}
                            </h1>
                            <div class="auctionDate">
                                {{ date }}
                            </div>

                            <div class="liveBiddingButtonWrapper">
                                <a
                                    v-if="
                                        auction.auctionLiveBiddingLink &&
                                        !liveBiddingActive
                                    "
                                    class="button button-dark-grey"
                                    :href="auction.auctionLiveBiddingLink"
                                    target="_blank"
                                >
                                    {{ $t('livebidding_register') }}
                                </a>
                                <router-link
                                    class="button button-blue color-white"
                                    :class="{
                                        active:
                                            $route.name ==
                                            'page.auctionLivebidding',
                                    }"
                                    v-if="liveBiddingActive"
                                    :to="{
                                        name: 'page.auctionLivebidding',
                                        params: {
                                            companySlug: auction.companySlug,
                                            auctionId: auction.auctionId,
                                        },
                                        query: this.$route.query,
                                    }"
                                >
                                    <span>{{ $t('livebidding.sixbid') }}</span>
                                </router-link>
                            </div>

                            <div
                                v-if="auction.auctionLiveBiddingLink"
                                class="clearfix"
                            ></div>

                            <div class="auctionICS no-print" v-if="!hasEnded">
                                <a
                                    :href="`${icsAPI}/calendar-export/lot/${lot.lotId}?lang=${$route.params.lang}`"
                                    >{{ $t('date_save') }}</a
                                >
                            </div>
                            <div class="clearfix"></div>
                            <div class="auctionOnlineBiddingEnd">
                                <span class="auctionOnlineBiddingEndLabel"
                                    >{{ $t('bid_end') }}:</span
                                >
                                <span class="auctionOnlineBiddingEndDate">
                                    <date-timezone :date="lot.sessionEnds" />
                                </span>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div
                            class="searchform no-print"
                            v-if="viewShown || !$global.isMobile"
                        >
                            <form v-on:submit.prevent="searchLots" class="form">
                                <div class="group">
                                    <input
                                        type="text"
                                        :placeholder="$t('search_term_lot')"
                                        name="term"
                                        id="term"
                                        v-model="lotSearchForm.term"
                                    />
                                </div>
                                <div class="form-submit search-submit">
                                    <input
                                        type="submit"
                                        class="button button-blue"
                                        :value="$t('auction_search_in')"
                                    />
                                </div>
                                <div class="clearfix"></div>
                            </form>
                        </div>
                    </sidebar-box>
                </aside>

                <div class="lot-content col col-md-9">
                    <loader ref="loader"></loader>
                    <router-link
                        tag="a"
                        class="company"
                        v-if="company.companyId"
                        :to="{
                            name: 'page.companyDetail',
                            params: { companySlug: company.companySlug },
                        }"
                    >
                        <company-image
                            class="hidden-below-md"
                            :alt="company.companyLogoAlt"
                            :title="company.companyLogoTitle"
                            :companyId="company.companyId"
                            :auctionId="auction.auctionId"
                        ></company-image>
                    </router-link>
                    <div class="row no-print">
                        <div class="col sm-col col-lg-4">
                            <router-link
                                v-if="lot.prev && lot.prev.lotId"
                                :to="{
                                    name: 'page.auctionLotDetail',
                                    params: {
                                        companySlug: company.companySlug,
                                        auctionId: auction.auctionId,
                                        categorySlug: lot.categorySlug,
                                        lotId: lot.prev.lotId,
                                        lotSlug: lot.prev.lotSlug,
                                    },
                                    query: this.$route.query,
                                }"
                            >
                                <i class="fas fa-caret-left"></i>
                                {{ $t('lot_previous') }}
                            </router-link>
                            <span v-else>
                                <i class="fas fa-caret-left"></i>
                                {{ $t('lot_previous') }}
                            </span>
                        </div>
                        <div class="col sm-col col-lg-4 centeralign">
                            <router-link
                                :to="{
                                    name: 'page.auctionLotList',
                                    params: {
                                        page: currentPage,
                                        perPage: itemsPerPage,
                                    },
                                    query: this.$route.query,
                                }"
                                >{{ $t('go_to_overview') }}</router-link
                            >
                        </div>
                        <div class="col sm-col col-lg-4 rightalign">
                            <router-link
                                v-if="lot.next && lot.next.lotId"
                                :to="{
                                    name: 'page.auctionLotDetail',
                                    params: {
                                        companySlug: company.companySlug,
                                        auctionId: auction.auctionId,
                                        categorySlug: lot.categorySlug,
                                        lotId: lot.next.lotId,
                                        lotSlug: lot.next.lotSlug,
                                    },
                                    query: this.$route.query,
                                }"
                            >
                                {{ $t('lot_next') }}
                                <i class="fas fa-caret-right"></i>
                            </router-link>
                            <span v-else>
                                {{ $t('lot_next') }}
                                <i class="fas fa-caret-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="image">
                        <coin-archive-link
                            v-if="lot.coinArchiveLink"
                            :link="lot.coinArchiveLink"
                            has-animation
                        />
                        <bookmark :lotId="lot.lotId" />

                        <div v-if="!!lot.hasImage" class="zoom">
                            <zoomer-container
                                v-if="
                                    lot.lotImageLargeUrl &&
                                    lot.lotImageLargeUrlOriginal
                                "
                                :image="lot.lotImageLargeUrl"
                                :zoom="lot.lotImageLargeUrlOriginal"
                            ></zoomer-container>
                        </div>
                        <div v-else class="zoomspacer">No Image</div>
                    </div>
                    <div class="row">
                        <div class="col col-lg-4">
                            <template v-if="lot.lotStartingPrice">
                                <strong>{{ $t('lot_price_start') }}:</strong>
                                <price
                                    :value="lot.lotStartingPrice"
                                    :currency="lot.auctionCurrency"
                                    :prAvailable="auction.auctionPrAvailable"
                                ></price>
                            </template>
                        </div>
                        <div class="col col-lg-4">
                            <template v-if="lot.lotEstimate">
                                <strong>{{ $t('lot_estimate') }}:</strong>
                                <price
                                    :value="lot.lotEstimate"
                                    :currency="lot.auctionCurrency"
                                    :prAvailable="auction.auctionPrAvailable"
                                ></price>
                            </template>
                        </div>
                        <div
                            class="col col-lg-4"
                            v-if="auction.auctionPrAvailable"
                        >
                            <strong>{{ $t('result') }}:</strong>
                            <price
                                :value="lot.lotPriceRealised"
                                :currency="lot.auctionCurrency"
                                :showZeroAsUnsold="true"
                            ></price>
                        </div>
                        <div class="col col-lg-4 bid-box" v-else>
                            <bid-lot-input
                                class="bid-input no-print"
                                @bid-placed="showMessage"
                                :lot="lot"
                                v-if="message.type != 'success'"
                                :detailStyling="true"
                            ></bid-lot-input>
                            <router-link
                                v-if="liveBiddingActive"
                                class="button button-blue fullwidth margin-top"
                                :to="{
                                    name: 'page.auctionLivebidding',
                                    params: {
                                        companySlug: lot.companySlug,
                                        auctionId: lot.auctionId,
                                    },
                                    query: this.$route.query,
                                }"
                                v-html="$t('livebidding.sixbid')"
                            >
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-lg-16 bid-lot-message-box">
                            <bid-lot-message
                                v-if="hasMessage"
                                :lot="lot"
                                :message-type="message.type"
                                :message-content="message.content"
                            ></bid-lot-message>
                        </div>
                    </div>

                    <div class="desc">
                        <span
                            v-html="
                                '<strong>' +
                                $t('lot') +
                                ' ' +
                                lot.lotNumber +
                                lot.lotNumberAffix +
                                '.</strong> ' +
                                lot.lotDescription
                            "
                            v-if="lot.lotDescription"
                        ></span>
                    </div>
                    <div class="prov" v-if="lot.lotProvenance">
                        <span>{{ $t('provenance') }}:</span>
                        {{ lot.lotProvenance }}
                    </div>
                </div>
            </div>
            <div class="row no-print">
                <div class="toolbar col col-md-12">
                    <social-share :lot="lot"></social-share>
                    <button
                        class="button button-grey"
                        onclick="window.print();"
                    >
                        <i class="fas fa-print"></i>{{ $t('print') }}
                    </button>
                </div>
            </div>
            <a
                class="similarLotsToggle"
                href="#"
                v-if="$global.isMobile && !showSimilarLots"
                v-on:click.prevent="showSimilarLots = !showSimilarLots"
                >{{ $t('lot_similar_lots') }}</a
            >
            <div
                class="row no-print"
                v-if="!($global.isMobile && !showSimilarLots)"
            >
                <div class="col col-lg-12">
                    <div class="similar-lots">
                        <h2>{{ $t('lot_similar_lots') }}</h2>
                        <p>
                            {{ $t('lot_similar_lots_text') }}
                        </p>
                        <similar-lot-list-component :lotId="similarLotId" />
                    </div>
                </div>
            </div>
        </div>

        <content-overlay :shown="overlayShown" @closing="overlayShown = false">
            <div v-html="ngcContent"></div>
        </content-overlay>
    </div>
</template>

<script>
import CloudImageGetter from '../lib/CloudImageGetter';
import AuctionTabs from '../components/AuctionTabs.vue';
import SocialShare from '../components/SocialShare.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import Price from '../components/Price.vue';
import SidebarBox from '../components/SidebarBox.vue';
import BidLotInput from '../components/BidLotInput.vue';
import Bookmark from '../components/Bookmark.vue';
import SimilarLotListComponent from '../components/SimilarLotListComponent';
import Loader from '../components/Loader';
import ZoomerContainer from '../components/ZoomerContainer.vue';
import ContentOverlay from '../components/ContentOverlay';
import CompanyImage from '../components/CompanyImage.vue';
import BidLotMessage from '../components/BidLotMessage.vue';
import CoinArchiveLink from '../components/CoinArchiveLink.vue';
import DateTimezone from '../components/DateTimezone.vue';
import { dateRange } from '@/filters/date';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { PAGINATION_DEFAULTS } from '../config.js';
import {
    getAuctionLiveBiddingStatusCode,
    getCompanyDetails,
    getAuctionDetails,
    getLotDetails,
    getWatchListIds,
    getNgcContent,
} from '../lib/apis';

export default {
    name: 'Auction_Lot_Detail_Page',
    metaInfo() {
        return {
            titleTemplate: this.$t(
                this.$t('lot') +
                    ': ' +
                    this.lot.lotNumber +
                    this.lot.lotNumberAffix +
                    ' | ' +
                    this.lotDescriptionPart +
                    ' | ' +
                    this.auction.auctionName +
                    ' | ' +
                    this.company.companyName +
                    ' | %s'
            ),
        };
    },
    components: {
        AuctionTabs,
        SocialShare,
        SimilarLotListComponent,
        Loader,
        ZoomerContainer,
        Breadcrumb,
        Price,
        BidLotInput,
        Bookmark,
        ContentOverlay,
        SidebarBox,
        CompanyImage,
        AuctionBanner,
        BidLotMessage,
        CoinArchiveLink,
        DateTimezone,
    },
    data: function () {
        return {
            showSimilarLots: false,
            company: {},
            auction: {},
            lot: {},
            similarLotsKey: 0,
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            hasMessage: false,
            overlayShown: false,
            ngcContent: '',
            message: {
                type: '',
                content: '',
            },
            lotSearchForm: {
                priceFrom: null,
                priceTo: null,
            },
            viewShown: false,
            auctionStatusLocal: {
                found: false,
                status: 0,
            },
        };
    },
    computed: {
        alertClass() {
            const className = `alert--${this.message.type}`;
            return {
                [className]: this.message.type !== '',
            };
        },
        icsAPI() {
            return `${process.env.VUE_APP_GLOBAL_API_URL}`;
        },
        similarLotId() {
            return Number(this.$route.params.lotId);
        },
        lotDescriptionPart() {
            let desc = this.$options.filters['striphtml'](
                String(this.lot.lotDescription)
            );
            desc = desc.split('.', 1);
            return desc;
        },
        date() {
            return dateRange(
                this.auction.auctionStart,
                this.auction.auctionEnd
            );
        },
        currentPage() {
            if (this.$route.query.page) {
                return this.$route.query.page;
            }
            return PAGINATION_DEFAULTS.START_PAGE;
        },
        itemsPerPage() {
            if (this.$route.query.limit) {
                return this.$route.query.limit;
            }
            return PAGINATION_DEFAULTS.PER_PAGE;
        },
        hasEnded() {
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const auctionEnd = dayjs
                .tz(this.auction.auctionOnlineEnd, 'Europe/Berlin')
                .local();
            const currentTime = new Date();

            return currentTime > auctionEnd;
        },
        liveBiddingActive() {
            if (typeof this.auctionStatus == 'undefined') {
                return (
                    this.auctionStatusLocal.found &&
                    this.auctionStatusLocal.status > 0
                );
            } else {
                return this.auctionStatus.found;
            }
        },
    },
    mounted: function () {
        this.fetchData();

        // setup periodic polling of livebidding status
        setInterval(
            function () {
                this.getLivebiddingStatus();
            }.bind(this),
            30000
        );
    },
    methods: {
        searchLots: function () {
            const query = {
                ...this.$route.query,
                term: this.lotSearchForm.term,
            };
            const currentPage =
                this.$route.query.page || PAGINATION_DEFAULTS.START_PAGE;
            const itemsPerPage =
                this.$route.query.limit || PAGINATION_DEFAULTS.PER_PAGE;
            this.$router.push({
                name: 'page.auctionLotList',
                params: { page: currentPage, perPage: itemsPerPage },
                query,
            });
        },
        fetchData() {
            this.$refs.loader.show();
            Promise.all([
                getCompanyDetails(
                    this.$route.params.companySlug,
                    this.$route.params.lang
                ),
                getAuctionDetails(
                    null,
                    this.$route.params.companySlug,
                    this.$route.params.auctionId,
                    this.$route.params.lang
                ),
                getLotDetails(
                    {
                        lotIds: [this.$route.params.lotId],
                        companySlug: this.$route.params.companySlug,
                        auctionId: this.$route.params.auctionId,
                    },
                    this.$route.params.lang
                ),
            ])
                .then(([company, auction, lots]) => {
                    this.company = company.data;
                    this.auction = auction.data;
                    this.lot = lots[0].data;

                    if (this.company.companySlug != this.auction.companySlug) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }

                    if (this.auction.auctionHasNGC) {
                        getNgcContent(this.$route.params.auctionId).then(
                            (response) => {
                                //console.log("NGC", response);
                                this.ngcContent = response.data;
                            }
                        );
                    }

                    this.lot.lotImageLargeUrl = CloudImageGetter(
                        this.lot.googleBucketImagePath,
                        'XL'
                    );

                    this.lot.lotImageLargeUrlOriginal = CloudImageGetter(
                        this.lot.googleBucketImagePath,
                        'O'
                    );

                    this.$refs.loader.hide();

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );

                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    if (e.response) {
                        if (e.response.status === 404) {
                            return this.$router.push(
                                `/${this.$route.params.lang}/404`
                            );
                        }
                    } else if (e.request) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }

                    this.genericApiErrorHandling(e);
                });
            getWatchListIds()
                .then((response) => {
                    this.$store.commit(
                        'lotWatchlist/setWatches',
                        response.data
                    );
                })
                .catch((/* error */) => {
                    // eslint-disable-next-line no-console
                    //console.log("getWatchListIds:catch", error);
                    // ignore error
                });
        },
        showMessage(message) {
            this.hasMessage = true;
            this.message.type = message.type;
            this.message.content = message.content;
        },
        getLivebiddingStatus: function () {
            if (this.auction.liveBiddingUrls && this.auction.auctionId) {
                const livebiddingProviderUrl = Object.values(
                    this.auction.liveBiddingUrls
                )[0];
                if (livebiddingProviderUrl) {
                    getAuctionLiveBiddingStatusCode(
                        livebiddingProviderUrl,
                        this.auction.auctionId
                    ).then((result) => {
                        this.auctionStatusLocal = result.data;
                    });
                }
            }
        },
    },
    watch: {
        lot: function () {
            // request once up-front so we don't need to wait
            this.getLivebiddingStatus();
        },
        $route: function () {
            this.hasMessage = false;
            this.message = {
                type: '',
                content: '',
            };
            this.fetchData();
        },
    },
};
</script>

<style scoped lang="scss">
.liveBiddingButtonWrapper {
    margin-bottom: 10px;

    a {
        width: 100%;
    }
}

.rightalign {
    @media (min-width: $lg-size) {
        text-align: right;
    }
}
.centeralign {
    @media (min-width: $lg-size) {
        text-align: center;
    }
}

.bid-lot-message-box {
    margin-top: 0;
}
.auction-lot-list-item {
    border: 1px solid lightgray;
    padding: 1em;
}
.similarLotsToggle {
    text-align: center;
    display: block;
    text-transform: uppercase;
    padding-top: 20px;
    text-decoration: none;
    &::before {
        content: '';
        position: relative;
        display: block;
        height: 2px;
        background: $grey-bg;
        margin: 0 0 $mar-xl 0;
    }
}
.sidebar-box {
    display: block;
    .searchform {
        margin-top: 3em;

        .form-submit,
        .form-submit .button {
            width: 100%;
        }
        @media (max-width: $md-size) {
            form {
                margin-bottom: -15px;
            }

            margin-top: 0;
            margin-bottom: -15px !important;
            background: #e7e7e7;
            padding: 15px 15px 30px 15px;
        }
    }
    background-color: transparent;
    @media (min-width: $md-size) {
        background-color: $grey-bg-lighter;
    }
    @media (max-width: $sm-size-max) {
        padding: $mar-xl 0;
    }
}
.bid-input {
    input {
        width: 80%;
    }
}
.bid-box {
    margin-top: 0;
    padding-top: 7px;
}
.search-submit {
    margin-top: 0.5em;
    float: right;
}
.desc {
    margin-top: 4em;
    font-weight: 300;
}

@media (max-width: $md-size-max) {
    .sm-col {
        width: 33%;
        .fas {
            display: none;
        }
    }
    .lot-content {
        margin-top: 0;
    }
    .lot-info {
        margin-top: 0;
    }
    .bid-box {
        margin-top: 1em;
    }
    .bid-input {
        float: none;
    }
}

.lot-info {
    @media (max-width: $sm-size-max) {
        .sidebar-box {
            padding: 0;
        }
    }
    .auctionOnlineBiddingEndLabel {
        font-weight: bold;
        display: block;
        margin-bottom: $mar-sm;
    }

    .auctionDate,
    .auctionICS {
        margin-bottom: $mar-lg;
    }

    @media (max-width: $sm-size-max) {
        .datesbox {
            padding: 5px 15px;
            margin-bottom: 0;
            margin-top: 0;
            background: $grey-bg-light;
        }

        .auction,
        .nr {
            font-size: 14px;
            font-family: $body-font;
        }

        .auctionDate,
        .auctionICS,
        .auctionOnlineBiddingEndLabel,
        .auctionOnlineBiddingEndDate {
            display: inline-block;
        }
        .clearfix {
            margin-bottom: 0;
        }
        .auctionDate,
        .auctionICS {
            margin-bottom: $mar-sm;
        }
        .auctionDate {
            max-width: 45%;
        }
        .auctionICS {
            max-width: 55%;
            margin-left: $mar-lg;
        }
        .auctionOnlineBiddingEnd {
            margin-bottom: 0;
        }
        .auctionOnlineBiddingEndLabel {
            max-width: 45%;
            float: left;
        }
        .auctionOnlineBiddingEndDate {
            max-width: 55%;
            margin-left: $mar-lg;
        }
    }
}

i {
    margin: 0 5px;
}

.cal {
    margin-top: -1.25em;
    span:first-child {
        @media (max-width: $sm-size-max) {
            display: none !important;
        }
    }
}

.lot-bid {
    background: $grey-bg-lighter;
    padding: $pad-lg;

    .alert {
        margin-top: $mar-md;
    }
}

.lot-nav {
    & > div {
        display: flex;
        justify-content: space-between;

        span {
            color: $grey-bg-dark;
        }

        & + div {
            margin-top: $mar-md;
        }

        & > * {
            display: inline-block;

            &:nth-child(2) {
                text-align: right;
            }
        }
    }
}

.more {
    text-align: center;

    @media (min-width: $md-size) {
        display: none;
    }

    &::before {
        content: '';
        position: relative;
        display: block;
        max-width: 150px;
        height: 2px;
        background: black;
        margin: $mar-xl auto;
    }
}

.lot-content {
    position: relative;
    @media (min-width: $md-size) {
        background-color: $grey-bg-lighter;
    }
    padding-bottom: 2em;
    @media (min-width: $md-size) {
        width: calc(75% - 30px);
    }
    & > div + div {
        margin-top: $mar-lg;
    }
    .company picture {
        margin-top: $mar-lg;
    }
}

.toolbar {
    position: relative;
    text-align: right;
    @media (min-width: $md-size) {
        width: calc(100% - 30px);
        padding-right: 0;
    }
}

.company {
    cursor: pointer;
    border-top: 1px solid $grey-bg-light;
    border-bottom: 1px solid $grey-bg-light;
}

.zoom {
    position: relative;
    border: 1px solid $grey-bg-light;
    text-align: center;
    padding: $pad-xl;

    & + div {
        position: relative;
        padding: 3px $pad-lg;
        margin: 0 auto;
        text-align: center;
        background: $grey-bg-light;

        @media (min-width: $lg-size) {
            min-width: 400px;
            width: 40%;

            &::after,
            &::before {
                top: 0;
                position: absolute;
                content: '';
                border-top: 23px solid $grey-bg-light;
                width: 0;
            }

            &::before {
                border-left: 23px solid transparent;
                left: -23px;
            }

            &::after {
                border-right: 23px solid transparent;
                right: -23px;
            }
        }
    }
}

.zoomspacer {
    padding: $pad-xl;
    background: white;
    text-align: center;
    color: $grey-bg-dark;
}

.image {
    position: relative;

    .zoom {
        background-color: white;
    }

    &-mobile {
        display: block;

        @media (min-width: $md-size) {
            display: none;
        }
    }
}

.similar-lots {
    position: relative;
    padding-top: $pad-xl;

    &::before {
        content: '';
        position: relative;
        display: block;
        height: 2px;
        background: black;
        margin: 0 0 $mar-xl 0;
        @media (min-width: $md-size) {
            margin-right: 18px;
        }
    }

    h2,
    p {
        text-align: center;
    }
}

.ngc {
    display: inline-block;
    background: $ngc-color;
    padding: 3px 10px;
    color: white;
    cursor: pointer;
}

.fullwidth {
    width: 100%;
}

.margin-top {
    display: inline-block;
    margin-top: 10px;
}

// Print Styles
@media print {
    .row:not(.no-print) {
        display: block;
    }

    .col {
        width: 100%;
        max-width: 800px;
        padding: 5px 0;
        display: block;
        vertical-align: top;
    }

    * {
        margin: 0 !important;
    }
}
</style>
